* {
  padding:0;
  margin:0;
  box-sizing:border-box;
}
body {
  width:100%;
  overflow-x:hidden;
  margin-bottom:2rem;
}
