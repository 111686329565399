header {
  padding:1rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
header h1 {
  font-size:1.5rem;
  margin:1rem 0;
}
header p {
  font-size:1rem;
}
header a {
  color:inherit;
  text-decoration:none;
}
#order-box {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:1rem;
  width:90vw;
  margin:0 auto;
  height:100%;
  gap:1rem;
  border:dashed 2px #1e1e1e;
  border-radius:0.5rem;
}
label {
  font-size:1.25rem;
  font-weight:bold;
}
input {
  width:100%;
  padding:0.5rem;
  border-radius:0.5rem;
  border:2px solid #1e1e1e;
  font-size:1rem;
  text-align:center;
}
::placeholder, ::-webkit-input-placeholder {
  color:red;
}
#amount-box {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap:1rem;
}
#amount-box .amount-option {
  color: #1e1e1e!important;
  border:solid 2px;
  border-color: #1e1e1e;
  border-radius: 100px;
  font-size: 0.75rem;
  font-family: 'Inter',Helvetica,Arial,Lucida,sans-serif!important;
  font-weight: 700!important;
  text-transform: uppercase!important;
  background-color: RGBA(255,255,255,0);
  padding: 0.5rem 1rem;
  cursor:pointer;
  transition: all 0.5s!important;
}
#amount-box .amount-option.active {
  color: #fff!important;
  background: #1e1e1e;
}
input:focus {
  outline:none;
  border:2px solid #1e1e1e;
}
#price-box #price{
  color:#63c565;
  font-size:1.5rem;
  font-weight:bold;
}
#next-button,#pay-button {
  width:100%;
  padding:0.5rem;
  border-radius:0.5rem;
  border:1px solid #ccc;
  font-size:1rem;
  background-color:#63c565;
  color:#fff;
  font-weight:bold;
  cursor:pointer;
  transition: all 0.5s;
  text-transform:uppercase;
}
#next-button:not([disabled]),#pay-button:not(.retry) {
  animation: pulse 2s infinite;
}
button:disabled {
  opacity:0.75;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}